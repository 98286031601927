import Choices from "choices.js";
import "choices.js/public/assets/styles/choices.min.css";

document.addEventListener("DOMContentLoaded", function () {
    const multiselectPersons = document.getElementById(
        "add-multiselect-persons"
    );
    const planDropdown = document.getElementById("plan-id");
    let choicesInstance;

    const updateMaxUsers = () => {
        if (planDropdown) {
            const { selectedIndex, options } = planDropdown;
            return parseInt(
                options[selectedIndex].getAttribute("data-max-users"),
                10
            );
        }
    };

    const initializeChoices = (maxUsers = updateMaxUsers()) => {
        if (choicesInstance) {
            choicesInstance.destroy();
        }

        choicesInstance = new Choices(multiselectPersons, {
            placeholder: true,
            placeholderValue: "Personen auswählen",
            removeItemButton: true,
            duplicateItemsAllowed: false,
            closeDropdownOnSelect: true,
            maxItemCount: maxUsers,
            maxItemText: (maxItemCount) =>
                `Dieser Plan kann pro Lizenz maximal von ${maxItemCount} Person${
                    maxItemCount > 1 ? "en" : ""
                } genutzt werden.`,
        });
    };

    if (planDropdown) {
        planDropdown.addEventListener("change", function () {
            initializeChoices();
        });
    }

    if (multiselectPersons) {
        multiselectPersons.addEventListener("change", function () {
            initializeChoices();
        });
    }

    initializeChoices();
});
