document.addEventListener("DOMContentLoaded", function () {
    if (!document.querySelector(".Navigation-linksContainer")) {
        return;
    }

    const navigationSettingsContainer = document.querySelector(
        ".Navigation-settingsContainer"
    );
    const navigationDropDownSettings = document.querySelector(
        ".Navigation-dropDownContainer"
    );

    function isDropDownActive() {
        if (
            navigationDropDownSettings.classList.contains(
                "Navigation-dropDownContainer--active"
            )
        ) {
            navigationSettingsContainer.classList.add(
                "Navigation-settingsContainer--active"
            );
        } else {
            navigationSettingsContainer.classList.remove(
                "Navigation-settingsContainer--active"
            );
        }
    }

    navigationSettingsContainer.addEventListener("click", function () {
        navigationDropDownSettings.classList.toggle(
            "Navigation-dropDownContainer--active"
        );
        isDropDownActive();
    });

    window.addEventListener("click", function (event) {
        if (event.target !== navigationSettingsContainer) {
            navigationDropDownSettings.classList.remove(
                "Navigation-dropDownContainer--active"
            );
        }
        isDropDownActive();
    });
});
