import Choices from "choices.js";
import "choices.js/public/assets/styles/choices.min.css";

document.addEventListener("DOMContentLoaded", function () {
    const personMultiselect = document.getElementById(
        "edit-multiselect-persons"
    );
    const planDropdown = document.getElementById("edit-plan-id");
    let choicesInstance;

    const updateMaxUsers = () => {
        const { selectedIndex, options } = planDropdown;
        return parseInt(
            options[selectedIndex].getAttribute("data-max-users"),
            10
        );
    };

    const initializeChoices = (maxUsers = updateMaxUsers()) => {
        if (choicesInstance) {
            choicesInstance.destroy();
        }

        choicesInstance = new Choices(personMultiselect, {
            placeholder: true,
            placeholderValue: "Personen auswählen",
            removeItemButton: true,
            duplicateItemsAllowed: false,
            closeDropdownOnSelect: true,
            maxItemCount: maxUsers,
            maxItemText: (maxItemCount) =>
                `Dieser Plan kann pro Lizenz maximal von ${maxItemCount} Person${
                    maxItemCount > 1 ? "en" : ""
                } genutzt werden.`,
        });
    };

    const handleDropdownChange = () => {
        initializeChoices();
    };

    if (planDropdown) {
        planDropdown.addEventListener("change", handleDropdownChange);
    }

    if (personMultiselect) {
        personMultiselect.addEventListener("change", handleDropdownChange);
    }
});
